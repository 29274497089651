import React from "react"
import Container from "components/Container"
import Heading from "components/Heading"
import { Link } from "@reach/router"

import "./404.scss"

export default function Default() {
  return (
    <>
      <Heading
        src="https://teenranch.nyc3.digitaloceanspaces.com/website/assets/wham10.jpg"
        alt="Upside down on the giant swing"
        title="404 Not found"
        metaTitle="404 Not found"
        subtitle="Uh-oh! Looks like the page you were looking for doesn't exist."
        metaDescription="Uh-oh! Looks like the page you were looking for doesn't exist."
      />
    </>
  )
}
